import jwtDecode from "jwt-decode"
import { IDP } from "./aoc"

export type ExtractedTokenInfo = {
    email: string,
    idp: IDP
}

export const extractInfoFromToken = (token: string) : ExtractedTokenInfo => {
    const extracted = jwtDecode<any>(token);
    return {
        email: extracted.email,
        idp: extracted.public_provider_name
    }
}