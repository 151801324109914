import { createContext, ReactNode, useContext } from 'react';
import { Loading } from '../components/common/Loading';
import { AOCConfig } from '../service/config';

export const ConfigContext = createContext<AOCConfig | undefined>(undefined);

export const useConfig = (): AOCConfig => {
    return useContext(ConfigContext) as AOCConfig;
};

export const ConfigBoundary = ({ config, children }: { config: AOCConfig; children: ReactNode }) => {
    if (!config) {
        return <Loading />;
    }
    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
