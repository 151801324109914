import { createContext, ReactNode, useContext, useMemo } from 'react';

const PREVIOUS_REQUEST = 'previous_request';

export const RequestContext = createContext<string | undefined>(undefined);

export const useRequestId = (): string => {
    return useContext(RequestContext) as string;
};

export const RequestBoundary = ({ children }: { children: ReactNode }) => {
    const requestId = useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        let requestId = params.get('requestId');
        if (requestId) {
            window.localStorage.setItem(PREVIOUS_REQUEST, requestId);
        } else {
            requestId = window.localStorage.getItem(PREVIOUS_REQUEST) ?? null;
            if (requestId) {
                params.set('requestId', requestId);
            }
        }
        window.history.replaceState({}, document.title, '/?' + params.toString());
        return requestId;
    }, []);

    if (!requestId) {
        throw new Error('Missing request id. Please check the link you were given.');
    }

    return <RequestContext.Provider value={requestId}>{children}</RequestContext.Provider>;
};
